import React from "react"

import Layout from "../components/layout"
import Page from "../components/page"

import { Frame, Paragraph, Heading, Button } from "arwes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithubSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

import Loading from '../components/loading';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading) {
      return (<Loading />);
    }

    return (<Layout>
      <Page>
        <div style={{padding: "20px", maxWidth: "500px"}}>
          <Frame animate layer='primary' corners={4}>
            {anim => (
              <div style={{paddingTop: "10px"}}>
                <Heading node='h1' animate show={anim.entered}>Nicholas Wong</Heading>
                <Heading node='h4' animate show={anim.entered}>APM @ Google, Harvard '20</Heading>
              </div>
            )}
            
          </Frame>
        </div>

        <Frame animate layer='secondary' corners={4}>
          <div style={{paddingTop: "10px"}}>
            <Paragraph style={{maxWidth: "800px"}}>Bioengineering computer scientist with a cybersecurity addiction. When he's not creating products for a safer, more accessible future, you'll find him in the nearest makerspace (probably repairing a 3D printer) or restaurant.</Paragraph>
          </div>
        </Frame>

        <div style={{paddingTop: "20px"}}>
          <a href="https://github.com/powerhouseofthecell" target="_blank" rel="noopener noreferrer">
            <Button animate layer="control" style={{paddingRight: "20px"}}>
              <FontAwesomeIcon icon={faGithubSquare} size="2x"/>
            </Button>
          </a>

          <a href="https://www.linkedin.com/in/nicholasfranciscowong/" target="_blank" rel="noopener noreferrer">
            <Button animate layer="control">
              <FontAwesomeIcon icon={faLinkedin} size="2x"/>
            </Button>
          </a>
        </div>
      </Page>
    </Layout>)
  }
}

export default IndexPage
